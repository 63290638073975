// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-panorama-messedemo-js": () => import("./../../../src/pages/panorama/messedemo.js" /* webpackChunkName: "component---src-pages-panorama-messedemo-js" */),
  "component---src-pages-panorama-panorama-galerie-js": () => import("./../../../src/pages/panorama/panorama-galerie.js" /* webpackChunkName: "component---src-pages-panorama-panorama-galerie-js" */),
  "component---src-pages-panorama-samsung-js": () => import("./../../../src/pages/panorama/samsung.js" /* webpackChunkName: "component---src-pages-panorama-samsung-js" */),
  "component---src-pages-panorama-telekom-js": () => import("./../../../src/pages/panorama/telekom.js" /* webpackChunkName: "component---src-pages-panorama-telekom-js" */)
}

